<template lang="pug">
.p-8
  dashboardTitle(:title="`Buscador de recetas`")
  inputText(
    :iconPath="mdiMagnify",
    insetActionButton="Buscar",
    v-model="keywordsSearch",
    v-on:clickButton="searchReceipt"
  )
  .flex.my-4.gap-2.flex-wrap
    .chimp(
      v-for="category in listCategories",
      :key="category._id",
      @click="changeSelectedCategory(category._id)",
      :class="{ active: categorySelected == category._id }"
    ) {{ category.category_name }}
  .flex.mt-8.justify-between
    .title Recetas
  .grid.grid-cols-2.gap-8(v-if="listReceipts.length == 0")
    skeletonCard(v-for="i in 6", :key="i") 
  .grid.grid-cols-2.gap-12(v-else-if="listReceipts.length > 0")
    defaultCard(
      v-for="receipt in listReceipts",
      :key="receipt._id",
      :receipt="receipt"
    ) 
</template>
<script>
import { mdiMagnify } from "@mdi/js";
import { ref, watch } from "vue";
import { ReceiptsRepository } from "@/app/repository/receipts.repository";
export default {
  name: "receipts.finder",
  data: () => ({
    mdiMagnify,
  }),
  setup() {
    const listCategories = ref([]);
    const listReceipts = ref([]);
    const categorySelected = ref(null);
    const keywordsSearch = ref("");

    ReceiptsRepository.getAllReceiptCategories()
      .then((data) => {
        listCategories.value = data;
      })
      .catch((e) => console.log(e));

    const changeSelectedCategory = async (category_id) => {
      categorySelected.value = category_id;
      await searchReceipt();
    };

    const searchReceipt = async () => {
      listReceipts.value = [];
      const data = await ReceiptsRepository.findReceipts(
        keywordsSearch.value,
        categorySelected.value
      );
      console.log(data);
      listReceipts.value = data.receipts;
    };

    searchReceipt();

    return {
      keywordsSearch,
      listCategories,
      listReceipts,
      changeSelectedCategory,
      categorySelected,
      searchReceipt,
    };
  },
};
</script>
<style lang="scss" scoped>
.chimp {
  @apply text-gray-400 font-medium py-1 px-4 rounded-full cursor-pointer;

  &:hover {
    @apply bg-gray-100 ring-1 ring-inset ring-gray-200 text-gray-500;
  }
  &.active {
    @apply bg-green-600 text-white font-medium py-1 px-4 rounded-full ring-0;
  }
}

.title {
  @apply text-xl font-semibold text-gray-400 uppercase pb-4;
}
</style>