import { ApiAxios } from './repository.config';

const RESOURCE_CAT = '/categories';
const RESOURCE_RECEIPTS = '/receipts';

export const ReceiptsRepository = {
  async getAllReceiptCategories() {
    try {
      const { data } = await ApiAxios().get(RESOURCE_CAT);
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async findReceipts(name: string, category_id: string) {
    try {
      const { data } = await ApiAxios().post(RESOURCE_RECEIPTS, {
        receipt_name: name,
        category_id
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
};
